import { useEffect } from 'react';
import FlightListTable from './FlightListTable/FlightListTable';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import NoDataMessageHeader from '../NoDataMessageHeader/NoDataMessageHeader';
import useFlightListTableData from '../../../hooks/useFlightListTableData/useFlightListTableData';
import { useFilterSearchDatesStore, useFilterStore } from '../../../hooks/useFilterStore/useFilterStore';
import { getFlightsHeaderDisplayDateString } from '../../../lib/displayUtils';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { NoDataMessage, ScrollLocalStorageElementId, Treatment } from '../../../lib/constants';
import { getComponentScroll, getStoredObject } from '../../../lib/utils';
import { useViewId } from '../../../contexts/ViewIdContext/ViewIdContext';
import FlightListTableVirtualizer from './FlightListTableVirtualizer/FlightListTableVirtualizer';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import './FlightListTableContainer.css';

/**
 * Container component for the flight list table view
 * @param {Object} props
 * @param {Object} props.savedSort - The saved sort object
 * @param {Function} props.onSortClick - The sort click handler
 * @param {Function} props.openDetailPane - The open detail pane handler
 * @param {Boolean} props.isPaneOpen - Whether or not the detail pane is open
 * @param {String} props.summaryPanelMode - The summary panel mode
 * @returns
 */
function FlightListTableContainer({
  savedSort,
  onSortClick,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
}) {
  const { startDate, endDate } = useFilterSearchDatesStore();
  const { loading, flightData, error } = useFlightListTableData();
  const { trackPageView } = useAppInsightsContext();
  const { timezone } = useFilterStore();
  const { showFeature } = useFeatureFlag();
  const showTableVirtualizer = showFeature(Treatment.TABLE_VIRTUALIZATION);
  const currentActiveViewId = useViewId();

  useEffect(() => {
    const prevScrollPositions = getComponentScroll(
      currentActiveViewId.id,
      ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
    );
    if (prevScrollPositions.scrollLeft === undefined || prevScrollPositions.scrollTop === undefined) {
      // When no current scroll is setup we initialize the local storage state for scroll
      const currentScrollObj = getStoredObject(ScrollLocalStorageElementId.FLIGHTLIST_TABLE) ?? {};
      localStorage.setItem(
        ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
        JSON.stringify({
          ...currentScrollObj,
          [currentActiveViewId.id]: {},
        }),
      );
    }
  }, []);

  useEffect(() => {
    trackPageView('FlightsTablePage');
  }, [trackPageView]);

  if (loading) {
    return <LoadingIndicator />;
  } else if (error) {
    return <NoDataMessageHeader message={NoDataMessage.FLIGHTS_ERROR} />;
  } else if (flightData.length === 0) {
    return <NoDataMessageHeader message={NoDataMessage.FILTER_YIELDS_NO_DATA} />;
  }

  return (
    <>
      <div className="flight-list-table-container-header" data-cy="flight-list-table-container-header">
        {getFlightsHeaderDisplayDateString(startDate, endDate, timezone)}
      </div>
      {showTableVirtualizer ? (
        <FlightListTableVirtualizer
          flightData={flightData}
          savedSort={savedSort}
          openDetailPane={openDetailPane}
          isPaneOpen={isPaneOpen}
          summaryPanelMode={summaryPanelMode}
          onSortClick={onSortClick}
          handleChangeActivityKey={handleChangeActivityKey}
        />
      ) : (
        <FlightListTable
          flightData={flightData}
          savedSort={savedSort}
          openDetailPane={openDetailPane}
          isPaneOpen={isPaneOpen}
          summaryPanelMode={summaryPanelMode}
          onSortClick={onSortClick}
          handleChangeActivityKey={handleChangeActivityKey}
        />
      )}
    </>
  );
}

export default FlightListTableContainer;
