import dayjs from 'dayjs';

/**
 * This method ensures that all user saved configurations are applied correctly.
 * JSON.parse(stringified object) can reconstitute an object from a string...however
 * when we store the date objects from Day JS they do not retain their Object status
 * because these objects are not serializable. So when we stringify a day js object
 * it turns it into its string timestamp. So we need to rehydrate it as a day js object
 *
 * This method should be used for MultiView
 * @param {Object} configState
 * @returns
 */
export const rehydrateConfigSaveForMultiView = (configState) => {
  return {
    ...configState,
    viewWindowData: configState.viewWindowData.map((viewData) => rehydrateConfigSaveForSingleView(viewData)),
  };
};

/**
 * This method is utilized for none MultiView modes where the filter reducer is not in viewWindowData.
 */
export const rehydrateConfigSaveForSingleView = (configState) => {
  return {
    ...configState,
    filterReducer: {
      ...configState.filterReducer,
      filter: rehydrateDayJsObjectForFilter(configState.filterReducer.filter),
    },
  };
};

const rehydrateDayJsObjectForFilter = (filterData) => {
  return {
    ...filterData,
    startDate: dayjs(filterData.startDate),
    endDate: dayjs(filterData.endDate),
  };
};
