import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FlightListTableContainer from '../FlightListTableContainer/FlightListTableContainer';
import GanttChart from '../GanttChart/GanttChart';
import { SwapFooter } from '../SwapMode/SwapFooter/SwapFooter';
import { DateFormat, FlightView, SummaryPanelMode } from '../../../lib/constants';
import SummaryPanel from '../SummaryPanel/SummaryPanel';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { useFilterSearchDatesStore } from '../../../hooks/useFilterStore/useFilterStore';
import './OperationsView.css';

const SwapModeWrapper = ({ children, isSwapModeActive }) => {
  if (isSwapModeActive) {
    return (
      <div className="swap-mode-border-overlay">
        {children}
        <SwapFooter />
      </div>
    );
  } else {
    return children;
  }
};

SwapModeWrapper.propTypes = {
  children: PropTypes.node,
  isSwapModeActive: PropTypes.bool,
};

const defaultSort = {
  orderBy: 'etd',
  orderDirection: 'asc',
};

const OperationsView = ({
  isSwapModeActive,
  viewMode,
  handleSortClick,
  sort,
  id,
  showHeader,
  viewSize,
  isMaximized,
}) => {
  const [summaryPanelMode, setSummaryPanelMode] = useState(SummaryPanelMode.MINI_SUMMARY);
  const [activityKey, setActivityKey] = useState('Flight');
  const [summaryPanelHeight, setSummaryPanelHeight] = useState(0);

  // TODO - will need to read from this view's store
  const { startDate, endDate } = useFilterSearchDatesStore();

  const viewType = viewMode;

  /**
   * Event handler to change the activity key
   * @param {string} activityKey
   */
  const handleChangeActivityKey = useCallback((activityKey) => {
    setActivityKey(activityKey);
    setSummaryPanelMode(activityKey == null ? SummaryPanelMode.MINI_SUMMARY : SummaryPanelMode.EXPANDED_DETAIL);
  }, []);

  const handleChangeSummaryPanelHeight = (height) => {
    setSummaryPanelHeight(height);
  };

  /**
   *
   * @returns {string} - class names for the operations view
   */
  const flightToolkitActiveClassHandler = useCallback(() => {
    let classes = '';
    classes = isSwapModeActive ? ' swap-mode-active' : '';
    classes += summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? ' summary-panel-mini' : ' summary-panel-expanded';

    return classes;
  }, [isSwapModeActive, summaryPanelMode]);

  const startDateFormatted = useMemo(() => formatDateTime(startDate, DateFormat.DEFAULT), [startDate]);
  const endDateFormatted = useMemo(() => formatDateTime(endDate, DateFormat.DEFAULT), [endDate]);

  return (
    <div className={`operations-view${flightToolkitActiveClassHandler()}`}>
      <SwapModeWrapper
        isSwapModeActive={isSwapModeActive}
        flightToolkitActiveClassHandler={flightToolkitActiveClassHandler}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        activityKey={activityKey}
      >
        <div className="operations-view-main-content">
          {viewType === FlightView.GANTT_VIEW || isSwapModeActive ? (
            <GanttChart
              startDate={startDateFormatted}
              endDate={endDateFormatted}
              handleChangeActivityKey={handleChangeActivityKey}
              viewSize={viewSize}
              showHeader={showHeader}
              summaryPanelHeight={summaryPanelHeight}
              viewType={viewType}
              isMaximized={isMaximized}
            />
          ) : (
            <FlightListTableContainer
              savedSort={sort || defaultSort}
              openDetailPane={() => {}}
              isPaneOpen={false}
              onSortClick={handleSortClick}
              handleChangeActivityKey={handleChangeActivityKey}
            />
          )}
        </div>
      </SwapModeWrapper>
      <SummaryPanel
        summaryPanelMode={summaryPanelMode}
        activityKey={activityKey}
        onChangedSummaryPanelHeight={handleChangeSummaryPanelHeight}
        handleChangeActivityKey={handleChangeActivityKey}
      />
    </div>
  );
};

OperationsView.propTypes = {
  handleSortClick: PropTypes.func,
  sort: PropTypes.object,
  isSwapModeActive: PropTypes.bool,
  viewMode: PropTypes.string,
  id: PropTypes.string,
  showHeader: PropTypes.bool,
  viewSize: PropTypes.object,
};

export default OperationsView;
