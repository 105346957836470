import React, { useState } from 'react';
import Modal from '../../Shared/Modal/Modal';
import PropTypes from 'prop-types';
import FilterContainer from './FilterContainer/FilterContainer';
import { useFilterStore } from '../../../hooks/useFilterStore/useFilterStore';
import Button from '../../Shared/Button/Button';
import { KeyCodes, RefetchPageData } from '../../../lib/constants';
import { useAircraftList } from '../../../hooks/useAircraftList/useAircraftList';
import { useCancelQuery } from '../../../react-query/useCancelQuery/useCancelQuery';

import './FilterModal.css';
import { isEqual } from 'lodash';

/**
 * Creates an object of attribute to string key value pairs
 * to help track errors within the filter form.
 * The errors when falsy will be an empty string '' - IE no errors
 * The errors when truthy will be the error message to display.
 * @param {object} initialFilterObj
 * @returns
 */
const initializeFilterErrors = (initialFilterObj) => {
  return Object.keys(initialFilterObj).reduce((acc, key) => {
    acc[key] = '';
    return acc;
  }, {});
};
/**
 * The Filter Modal is the parent component of the filters.
 * It is responsible for reading and updating the filter store.
 * @param {bool} showFilterModal - whether to open the modal or not
 * @param {bool} isDisabled - whether applying a filter is disabled or not
 * @param {Function} setShowFilterModal - callback to show/hide modal
 * @param {Function} onFilterClick - callback to apply the filter
 * @param {Function} onFilterClearClick - callback to clear the filter
 * @returns FilterModal component
 */
export const FilterModal = ({ showFilterModal, setShowFilterModal, onFilterClick, onFilterClearClick }) => {
  const { filter, timezone } = useFilterStore();
  let updateInitialFilters = { ...filter };

  const [updatedFilter, setUpdatedFilter] = useState(updateInitialFilters);
  const [filterErrors, setFilterErrors] = useState(initializeFilterErrors(updateInitialFilters));
  const [clearFilter, setClearFilter] = useState(false);

  const startDate = filter.startDate;
  const endDate = filter.endDate;
  const aircraftList = useAircraftList(startDate, endDate);

  const { cancelQuery } = useCancelQuery();

  const isFilterButtonDisabled = () => {
    // If even a single true is found, the filter button should be disabled
    if (Object.values(filterErrors).find((err) => err)) {
      return true;
    }

    const conditions = [
      new Date(updatedFilter.startDate).getTime() === new Date(updateInitialFilters.startDate).getTime(),
      new Date(updatedFilter.endDate).getTime() === new Date(updateInitialFilters.endDate).getTime(),
      isEqual(updatedFilter.airline, updateInitialFilters.airline),
      isEqual(updatedFilter.fleets, updateInitialFilters.fleets),
      isEqual(updatedFilter.flightPhase, updateInitialFilters.flightPhase),
      isEqual(updatedFilter.assignmentList, updateInitialFilters.assignmentList),
      isEqual(updateInitialFilters.flight, updatedFilter.flight),
      isEqual(updatedFilter.aircraft, updateInitialFilters.aircraft),
      isEqual(updatedFilter.origin, updateInitialFilters.origin),
      isEqual(updatedFilter.destination, updateInitialFilters.destination),
      updatedFilter.alerts.length < 1 || isEqual(updatedFilter.alerts, updateInitialFilters.alerts),
    ];

    return conditions.every((condition) => condition);
  };

  const isApplyBtnDisabled = isFilterButtonDisabled();

  // FOOTER action buttons
  const formButtons = [
    <Button
      key="apply-btn"
      variant="primary"
      className="filter-modal-footer-button apply"
      tabIndex="0"
      onClick={() => handleFilterButtonClick()}
      data-cy="nav-filter-apply-btn"
      isDisabled={isApplyBtnDisabled}
      onKeyDown={(event) => handleFilterButtonKeyDown(event)}
    >
      Apply
    </Button>,
    <Button
      key="clear-btn"
      variant="secondary"
      className="filter-modal-footer-button clear"
      tabIndex="0"
      onClick={() => clearFilters()}
      data-cy="nav-filter-clear-btn clear"
      onKeyDown={(event) => handleClearButtonKeyDown(event)}
    >
      Clear
    </Button>,
  ];

  const handleFilterButtonKeyDown = (e) => {
    // Do not process filter apply if disabled.
    if (isApplyBtnDisabled) return;

    if (e.keyCode === KeyCodes.ENTER) {
      // Prevent the default behavior of the onKeyDown for Enter
      e.preventDefault();
      handleFilterButtonClick();
    }
  };

  /**CLEAR Button Key Down */
  const handleClearButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ENTER) {
      clearFilters();
    }
  };

  // APPLY Button Clicked
  const handleFilterButtonClick = () => {
    //Retain the hide canceled state
    onFilterClick({
      ...updatedFilter,
    });
    cancelQuery(RefetchPageData.GANTT);
    cancelQuery(RefetchPageData.FLIGHT_LIST_TABLE);
    setShowFilterModal(false);
  };

  /**sfunction to clear data
   */
  const clearFilters = () => {
    setUpdatedFilter(updateInitialFilters);
    setClearFilter(true);
    onFilterClearClick();
  };

  // handle UPDATED filter data
  const handleHandleFilterUpdate = (filter) => {
    setUpdatedFilter(filter);
  };

  //Handle click event of close button on the header to reset any field changes if 'apply'  button is not clicked
  const handleCloseButtonOnClick = () => {
    setUpdatedFilter(updateInitialFilters);
    setShowFilterModal(false);
  };
  const handleClearFilter = () => {
    setClearFilter(false);
  };

  // Sets the errors object to true/false when errors are present
  const handleOnError = (attribute, hasErrors) => {
    setFilterErrors((currentFilterErrs) => {
      return {
        ...currentFilterErrs,
        [attribute]: hasErrors,
      };
    });
  };

  //Runs on the first render
  React.useEffect(() => {
    //show filter modal dependency changes update initial filters value
    setUpdatedFilter(updateInitialFilters);
  }, [showFilterModal, clearFilter]);

  //Runs every time updateInitialFilters changes
  React.useEffect(() => {
    //show filter modal dependency changes update initial filters value
    if (updateInitialFilters.isDefaultFilter && !updatedFilter.isDefaultFilter) {
      setUpdatedFilter(updateInitialFilters);
    }
  }, [updateInitialFilters]);

  return (
    <Modal
      show={showFilterModal}
      title={'Filters'}
      customCSSTag="new-filter-modal"
      body={
        <FilterContainer
          onCloseModal={showFilterModal}
          className="new-flight-list-filter-modal-container"
          onFilterUpdate={handleHandleFilterUpdate}
          initialFilter={updatedFilter}
          aircraftList={aircraftList}
          timeZone={timezone}
          isClearFilter={clearFilter}
          handleClearFilter={handleClearFilter}
          handleOnError={handleOnError}
          errors={filterErrors}
        />
      }
      footerButtons={formButtons}
      onHide={setShowFilterModal}
      closeButtonOnClick={handleCloseButtonOnClick}
    />
  );
};

FilterModal.propTypes = {
  showFilterModal: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  setShowFilterModal: PropTypes.func,
  onFilterClick: PropTypes.func,
};
