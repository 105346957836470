import { useFilterStore } from '../../hooks/useFilterStore/useFilterStore';
import { useHideStore } from '../../hooks/useHideFilterStore/useHideFilterStore';
import { useWindowManagerStore } from '../../hooks/useWindowManagerStore/useWindowManagerStore';
import { useSelector } from 'react-redux';
import { TimeZones, FlightView } from '../../lib/constants';
import { getDefaultFilter, jsonEqual } from '../../lib/utils';
import { useConfigStore } from '../../hooks/useConfigStore/useConfigStore';
import { useViewConfigurationStore } from '../../hooks/useViewConfigurationStore/useViewConfigurationStore';
import dayjs from 'dayjs';

const SEA_TIMEZONE = TimeZones.PDT;

const ConfigurationMapper = () => {
  const { filter, defaultAssignmentFilter, operatingAirlineByRole } = useFilterStore();
  const { hideCancelledFlights } = useHideStore();
  const viewMetaData = useWindowManagerStore();
  const { singleViewType } = useConfigStore();
  const viewConfigurationData = useViewConfigurationStore();

  const viewWindowData = useSelector((state) => state.viewWindowData);

  const mapConfigFilter_ToStateFilter = (filterParam) => {
    const timeZone = filterParam.timezone ?? TimeZones.UTC;
    const startDateCalc = dayjs().tz(SEA_TIMEZONE).add(filterParam.startDateDaysFromToday, 'day').format('MM-DD-YYYY');
    const endDateCalc = dayjs().tz(SEA_TIMEZONE).add(filterParam.endDateDaysFromToday, 'day').format('MM-DD-YYYY');
    return {
      startDate: dayjs(startDateCalc).tz(SEA_TIMEZONE, true).startOf('day'),
      endDate: dayjs(endDateCalc).tz(SEA_TIMEZONE, true).endOf('day'),
      flight: filterParam?.flight ?? [],
      aircraft: filterParam?.aircraft ?? [],
      origin: filterParam?.origin ?? [],
      destination: filterParam?.destination ?? [],
      assignmentList: toNumberArray(filterParam?.assignmentList) ?? [],
      flightPhase: toNumberArray(filterParam?.flightPhase) ?? [],
      airline: toNumberArray(filterParam?.airline) ?? [],
      alerts: filterParam?.alerts ?? [],
      fleets: filterParam?.fleets ?? [],
      timezone: timeZone,
    };
  };
  const getFilterReducer = (filterLocal, defaultAssignmentFilter, operatingAirlineByRole) => {
    let _filter = mapConfigFilter_ToStateFilter(filterLocal);
    const _isDefaultFilter = jsonEqual(_filter, getDefaultFilter(defaultAssignmentFilter, _filter.timezone));
    return {
      filter: _filter,
      previousFilter: null,
      isDefaultFilter: _isDefaultFilter,
      isSwapModeFilter: false,
      defaultAssignmentFilter: defaultAssignmentFilter,
      timezone: filterLocal?.timezone ?? TimeZones.UTC,
      operatingAirlineByRole: operatingAirlineByRole,
    };
  };
  const toStringArray = (arr) => {
    return arr.map((a) => a.toString());
  };
  const toNumberArray = (arr) => {
    return arr.map((a) => parseInt(a));
  };
  const mapStateFilter_ToConfigFilter = (filterLocal) => {
    const startDateDaysFromToday = dayjs(dayjs(filterLocal.startDate).format('MM-DD-YYYY')).diff(
      dayjs().format('MM-DD-YYYY'),
      'day',
      false,
    );

    const endDateDaysFromToday = dayjs(dayjs(filterLocal.endDate).format('MM-DD-YYYY')).diff(
      dayjs().format('MM-DD-YYYY'),
      'day',
      false,
    );

    return {
      // filterLocal
      startDateDaysFromToday: startDateDaysFromToday,
      endDateDaysFromToday: endDateDaysFromToday,
      flight: toStringArray(filterLocal.flight),
      aircraft: filterLocal.aircraft,
      origin: filterLocal.origin,
      destination: filterLocal.destination,
      assignmentList: toStringArray(filterLocal.assignmentList),
      flightPhase: toStringArray(filterLocal.flightPhase),
      airline: toStringArray(filterLocal.airline),
      alerts: toStringArray(filterLocal.alerts),
      fleets: filterLocal.fleets,
      timezone: filterLocal.timezone,
    };
  };

  const MapSingleViewState_ToUserConfig = () => {
    const singleViewWindow = {
      id: 0,
      viewName: "Today's Rotation",
      viewType: singleViewType.toString(),
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 800,
        height: 800,
      },
      viewOrder: 0,
      isMinimized: false,
      isMaximized: false,
      isDraggedMinimized: false,
      hideCancelledFlights: hideCancelledFlights, // Hide Cancelled Flights
      filter: mapStateFilter_ToConfigFilter(filter),
      ganttViewConfiguration: {
        // gantt view configuration
        hoursBefore: viewConfigurationData?.hoursBefore ?? null,
        hoursAfter: viewConfigurationData?.hoursAfter ?? null,
        numberOfAircrafts: viewConfigurationData?.numberOfAircrafts ?? null,
        timelineHours: viewConfigurationData?.timelineHours ?? null,
        puckSize: viewConfigurationData?.puckSize ?? null,
      },
    };
    return {
      browserSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      viewWindows: [singleViewWindow],
    };
  };

  const MapUserConfig_ToSingleViewState = (userConfig) => {
    const singleViewWindow = userConfig?.configurationDocument?.viewWindows[0];
    if (!singleViewWindow) {
      return null;
    }
    const filterLocal = singleViewWindow.filter;
    const ganttViewConfiguration = singleViewWindow.ganttViewConfiguration;
    let _filterReducer = getFilterReducer(filterLocal, defaultAssignmentFilter, operatingAirlineByRole);

    return {
      state: {
        configReducer: {
          openSaveConfig: false,
          activeConfigurationId: userConfig?.configurationId,
          singleViewType: singleViewWindow.viewType,
        },
        hideFilterReducer: {
          hideCancelledFlights: singleViewWindow.hideCancelledFlights,
        },
        filterReducer: _filterReducer,
        viewConfigurationReducer: {
          hoursBefore: ganttViewConfiguration?.hoursBefore,
          hoursAfter: ganttViewConfiguration?.hoursAfter,
          numberOfAircrafts: ganttViewConfiguration?.numberOfAircrafts,
          timelineHours: ganttViewConfiguration?.timelineHours,
          puckSize: ganttViewConfiguration?.puckSize,
        },
      },
      metadata: {
        browserSize: {
          width: userConfig?.configurationDocument?.browserSize?.width,
          height: userConfig?.configurationDocument?.browserSize?.height,
        },
      },
    };
  };

  const MapMultiViewState_ToUserConfig = () => {
    const { viewWindows, viewOrder, draggedMinimizedViews, maximizedViews, minimizedViews } = viewMetaData;
    const multiViewData =
      viewWindowData?.map((viewWindowDataItem) => {
        const viewWindow = viewWindows?.find((x) => x.id === viewWindowDataItem.id) || {};

        const { id, hideFilterReducer, filterReducer, viewConfigurationReducer } = viewWindowDataItem;
        const {
          viewTitle = '',
          viewType = '',
          viewPosition = { x: 0, y: 0 },
          viewSize = { width: 600, height: 600 },
        } = viewWindow;

        return {
          id,
          viewName: viewTitle ?? `view - ${id}`,
          viewType: viewType.toString(),
          position: {
            x: Math.floor(viewPosition.x),
            y: Math.floor(viewPosition.y),
          },
          size: {
            width: Math.floor(viewSize.width) || 600,
            height: Math.floor(viewSize.height) || 600,
          },
          viewOrder: viewOrder.indexOf(id),
          isMinimized: minimizedViews.includes(id),
          isMaximized: maximizedViews.includes(id),
          isDraggedMinimized: draggedMinimizedViews.includes(id),
          hideCancelledFlights: hideFilterReducer?.hideCancelledFlights,
          filter: mapStateFilter_ToConfigFilter(filterReducer?.filter),
          ganttViewConfiguration: {
            hoursBefore: viewConfigurationReducer?.hoursBefore ?? null,
            hoursAfter: viewConfigurationReducer?.hoursAfter ?? null,
            numberOfAircrafts: viewConfigurationReducer?.numberOfAircrafts ?? null,
            timelineHours: viewConfigurationReducer?.timelineHours ?? null,
            puckSize: viewConfigurationReducer?.puckSize ?? null,
          },
        };
      }) || [];

    return {
      browserSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      viewWindows: multiViewData,
    };
  };

  const MapUserConfig_ToMultipleViewState_GetViewByID = (userConfigFull, viewId) => {
    const userConfig = userConfigFull?.configurationDocument;
    const viewWindow = userConfig?.viewWindows.find((x) => x.id === viewId) || {};
    const filterLocal = viewWindow?.filter;
    if (filterLocal == undefined) return null;
    const ganttViewConfiguration = viewWindow.ganttViewConfiguration;
    let _filterReducer = getFilterReducer(filterLocal, defaultAssignmentFilter, operatingAirlineByRole);

    return {
      id: viewWindow.id,
      hideFilterReducer: {
        hideCancelledFlights: viewWindow.hideCancelledFlights,
      },
      filterReducer: _filterReducer,
      viewConfigurationReducer: {
        hoursBefore: ganttViewConfiguration?.hoursBefore,
        hoursAfter: ganttViewConfiguration?.hoursAfter,
        numberOfAircrafts:
          ganttViewConfiguration?.numberOfAircrafts == undefined ? 0 : ganttViewConfiguration?.numberOfAircrafts,
        timelineHours: ganttViewConfiguration?.timelineHours,
        puckSize: ganttViewConfiguration?.puckSize,
      },
    };
  };

  const MapUserConfig_ToMultipleViewState = (userConfigFull) => {
    let viewWindows = [];
    let viewOrder = [];
    let minimizedViews = [];
    let maximizedViews = [];
    let draggedMinimizedViews = [];

    let viewWindowData = [];

    const userConfig = userConfigFull?.configurationDocument;
    userConfig?.viewWindows.forEach((viewWindow) => {
      if (viewWindow.isMinimized) {
        minimizedViews.push(viewWindow.id);
      }
      if (viewWindow.isMaximized) {
        maximizedViews.push(viewWindow.id);
      }
      if (viewWindow.isDraggedMinimized) {
        draggedMinimizedViews.push(viewWindow.id);
      }

      // add viewWindow id to viewOrder array to maintain the order of the windows
      if (viewOrder.length > viewWindow.viewOrder) {
        viewOrder.splice(viewWindow.viewOrder, 0, viewWindow.id);
      } else {
        viewOrder.push(viewWindow.id);
      }

      viewWindows.push({
        id: viewWindow.id,
        viewTitle: viewWindow.viewName,
        viewType: parseInt(viewWindow.viewType),
        isViewFromConfig: true,
        viewPosition: {
          x: viewWindow.position.x,
          y: viewWindow.position.y,
        },
        viewSize: {
          width: viewWindow.size.width,
          height: viewWindow.size.height,
        },
      });

      let filterLocal = viewWindow.filter;
      let ganttViewConfiguration = viewWindow.ganttViewConfiguration;

      viewWindowData.push({
        id: viewWindow.id,
        hideFilterReducer: {
          hideCancelledFlights: viewWindow.hideCancelledFlights,
        },
        filterReducer: getFilterReducer(filterLocal, defaultAssignmentFilter, operatingAirlineByRole),
        viewConfigurationReducer: {
          hoursBefore: ganttViewConfiguration?.hoursBefore,
          hoursAfter: ganttViewConfiguration?.hoursAfter,
          numberOfAircrafts:
            ganttViewConfiguration?.numberOfAircrafts == undefined ? 0 : ganttViewConfiguration?.numberOfAircrafts,
          timelineHours: ganttViewConfiguration?.timelineHours,
          puckSize: ganttViewConfiguration?.puckSize,
        },
      });
    });

    return {
      state: {
        configReducer: {
          openSaveConfig: false,
          activeConfigurationId: userConfigFull?.configurationId,
          singleViewType: FlightView.GANTT_VIEW,
        },
        viewManagerReducer: {
          viewWindows: viewWindows,
          viewOrder: viewOrder,
          minimizedViews: minimizedViews,
          maximizedViews: maximizedViews,
          draggedMinimizedViews: draggedMinimizedViews,
        },
        viewWindowData: viewWindowData,
      },
      metadata: {
        browserSize: {
          width: userConfig?.browserSize?.width,
          height: userConfig?.browserSize?.height,
        },
      },
    };
  };

  return {
    MapSingleViewState_ToUserConfig,
    MapUserConfig_ToSingleViewState,
    MapUserConfig_ToMultipleViewState_GetViewByID,
    MapUserConfig_ToMultipleViewState,
    MapMultiViewState_ToUserConfig,
  };
};

export default ConfigurationMapper;
