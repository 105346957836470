import windowManagerReducer from './windowManagerReducer';
import rootReducer from './rootReducer';
import configReducer from './configReducer';
import { RootAction } from '../actionTypes';
import { isNullOrUndefined } from '../../lib/utils';

const multipleViewsRootReducer = (state, action) => {
  const { type, payload, updateAll = false } = action;
  if (type === RootAction.UPDATE_ROOTSTATE) {
    sessionStorage.removeItem('windowManagerStore');
    const defaultState = multipleViewsRootReducer({}, { type: 'INIT' });
    return {
      // default state gets applied first w/ root state payload getting applied after
      ...defaultState,
      ...payload.rootState,
      // Combine the viewWindow data from the root state payload and the default state
      viewWindowData: payload.rootState.viewWindowData.map((viewWindow) => {
        const currentId = viewWindow.id;
        let defaultViewState = defaultState.viewWindowData.find((viewData) => viewData.id === currentId);
        if (!defaultViewState) {
          defaultViewState = rootReducer({ id: currentId }, { type: 'INIT', id: currentId });
        }
        return {
          ...defaultViewState,
          ...viewWindow,
        };
      }),
    };
  }
  const activeId = state?.viewManagerReducer?.viewOrder[0] || 0;
  const updatedWindowState = windowManagerReducer(state?.viewManagerReducer, action);
  const update = {
    configReducer: configReducer(state?.configReducer, action),
    viewManagerReducer: updatedWindowState,
    viewWindowData:
      updatedWindowState?.viewWindows.map((viewWindow) => {
        const currentId = viewWindow.id;
        let currentIdState = state?.viewWindowData?.find((viewData) => viewData.id === currentId);
        if (!currentIdState) {
          currentIdState = rootReducer({ id: currentId }, { type: 'INIT', id: currentId });
        }
        return updateAll || (isNullOrUndefined(action.id) && currentId === activeId) || currentId === action.id
          ? rootReducer(currentIdState, { ...action })
          : currentIdState;
      }) || [],
  };
  return update;
};

export default multipleViewsRootReducer;
